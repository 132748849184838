.socials {
    position: relative;
    grid-area: socials;

    margin: 0 auto;
    padding-top: u($rule);
    padding-bottom: u($rule);

    z-index: 9999; 

    display: flex;
    flex-direction: row;
    
    width: 80px;
    
    justify-content: center;
    align-items: center;

    transition: opacity .3s, top .3s;

    .menu-open & {
        opacity: 0;
        pointer-events: none;
    }
    
    @include mq($from: $viewport--md) {
        position: fixed; 
        
        right: 0; 
        top: 50vh;
        
        transform: translateY(-50%);

        flex-direction: column;

        margin: unset;
        padding-top: 0;
        padding-bottom: 0;
    }
    
    &__item {
        max-height: 15px;

        width: u($rule*.6);
        height: u($rule*.6);

        svg {
            width: 100%;
            height: 100%;
        }
    }
    
    @include mq($from: $viewport--md) {
        &__item + &__item {
            margin-top: u($rule/2);
        }
    } 
}