.blog {

    $self: &;
    
    &__grid {

    }

    &__item {
        margin-bottom: u($rule/2);

        position: relative;

        @media (hover) {

            &:hover {
                #{$self}__text {
                    border-color: rgba($cc-orange, .3);
                }
                .button {
                    color: $cc-orange;
                }

                .icon svg {
                    fill: $cc-orange;
                }
            }
            
        }
    }

    &__image {
        position: relative;
    } 

    &__tag {

        position: absolute;

        top: 0;
        left: 0;

        background: $cc-black;
        color: $cc-white;
        
        padding: u($rule/3);
        
    }

    &__text {
        padding: u($rule);
        border: 2px solid $cc-grey-bg;
        border-top: none;

        display: flex;
        flex-direction: column;
        gap: u($rule/2);

        transition: border-color .3s ease;
        
        .button {
            justify-content: flex-start;

            transition: color .3s ease;
        }

        .text {
            color: $cc-grey; 

            p {
                margin: 0;
                padding: 0;
            }
        } 
    }
}

.blog__item, .masonry-item {
    width: 100%;
    
    @include mq($from: $viewport--md) {
        width: calc(33% - #{u(16 / 3 * 2)});
    }
} 