/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
// $font-Gotham:                      "Gotham", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900
$font-title:                            'DM Serif Display', Georgia, "Times New Roman", Times, serif !default; // normal
$font-default:                          'Gilroy', Helvetica, Arial, sans-serif !default; // bold normal 500 300 100

$weight-normal:                         400;
$weight-medium:                         500;
$weight-bold:                           700;



@font-face {
    font-family: 'DM Serif Display';
    src: url('/fonts/DMSerifDisplay-Regular.woff2') format('woff2'),
        url('/fonts/DMSerifDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('/fonts/DMSerifDisplay-Italic.woff2') format('woff2'),
        url('/fonts/DMSerifDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
        url('/fonts/Gilroy-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Medium.woff2') format('woff2'),
        url('/fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
        url('/fonts/Gilroy-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-LightItalic.woff2') format('woff2'),
        url('/fonts/Gilroy-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Bold.woff2') format('woff2'),
        url('/fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Light.woff2') format('woff2'),
        url('/fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-ThinItalic.woff2') format('woff2'),
        url('/fonts/Gilroy-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
        url('/fonts/Gilroy-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Thin.woff2') format('woff2'),
        url('/fonts/Gilroy-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Regular.woff2') format('woff2'),
        url('/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

