img {
    @include image-rendering($image-rendering);
    max-width: 100%;
    height: auto;
}

figure > img {
    display: block;
    
    &.img--cover {
        object-fit: cover;

        width: 100%;
        min-height: 100%;
        
        max-height: 850px;
    }
    
}
