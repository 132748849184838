.form {
    position: relative;

    margin-top: u($rule);

    .form__row {
        display: flex; 
        flex-direction: column;

        @include mq($from: $viewport--md) {
            flex-direction: row;
            justify-content: space-between;
        }

        .footer & {
            @include mq($from: $viewport--lg, $until: $viewport--xl--until) {
                flex-direction: column;
            }
        }

        width: 100%;

        & + .form__row {
            margin-top: u($rule/2);

            .footer & {
                margin-top: 0;
            }
        } 

    } 

    .form__field {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        label, input, textarea, select { 
            // font-family: $font-title; 
            // font-size: u($rule/2);
            padding: 0;
            background: $cc-white;
            border-radius: 0;
        }

        &.form__checkbox {
            .form__label {
                font-family: $font-default;
                font-size: 90%; 
                position: relative;
                line-height: 1.5;

                .link:before {
                    content: none;
                }
            }

            input[type=checkbox] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                ~ .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 1px solid $cc-grey-light;
                    z-index: 0;
                }

                &:checked ~ .checkmark {
                    background-color: $cc-orange;
                    border-color: $cc-orange;
                }

                ~ label {
                    padding-left: 25px;
                    cursor: pointer;
                }
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            
            input:checked ~ .checkmark:after {
                display: block;
            }
            
            .checkmark:after {
                left: 4px;
                top: 1px;
                width: 4px;
                height: 7px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }


        &.form__action {
            flex: 0; 
            flex-direction: unset;
            justify-content: flex-end;
        }

        @include mq($from: $viewport--md) {
            &.form__action .button {
                align-self: flex-end;
            }
        }

        &--required {

        } 

        &:not(.form__checkbox) { 
            .form__label {
                position: absolute;
                left: u($rule/2);
                top: 1px;
                z-index: 10;

                height: u($rule*1.5 - 2px);
                line-height: u($rule*1.5 - 2px);

                color: $cc-grey;
                
                transition: left 150ms ease-out, right 150ms ease-out;

                &.active {
                    left: unset;
                    right: u($rule/2);
                } 
            }
        } 
        
        .form__input {
            outline: none;
            width: 100%;

            padding-left: u($rule/2);
            padding-right: u($rule*2);

            height: u($rule*1.5);
            line-height: u($rule*1.5);

            border: 1px solid $cc-grey-light;

            &--hidden {
                display: none;
            }
        }

        textarea.form__input {
            height: u($rule*5);
            padding-top: u($rule/2);
            line-height: unset;
            resize: vertical;
        }

        .form__submit {
            cursor: pointer;

            padding-bottom: 2px;
            border-bottom: 1px solid $cc-black;

            transition: border-bottom-color .3s ease;

            margin-top: u($rule/2);

            @media (hover) {
                &:hover {
                    border-bottom-color: $cc-orange;
                }
            }


            @include mq($from: $viewport--md) {
                margin-top: 0;
            }
        }

        &.form__wysiwyg {
            display: flex; 
            color: $cc-grey;

            padding-right: 0;

            @include mq($from: $viewport--md) {
                padding-right:  u($rule*2);
            }

            a {
                text-decoration: underline;
            }
        }
    } 

    .ff-errors, .form__error, .freeform-form-has-errors, .ff-form-errors, .ff-form-success {
        color: $cc-orange;
        font-size: 90%;
        font-style: italic;
        margin-top: u($rule/2);
        margin-bottom: u($rule/2);
    } 


    .file-container {
        display: flex; 

        .fake-file {
            flex: 1; 

            margin-right: u($rule/2);

            color: $cc-grey;
            border: 1px solid $cc-grey-light;
        }

        .add-file-btn {
            flex: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            border: 1px solid $cc-grey-light;
        }
    }
    
    .fake-file, .add-file-btn {
        padding: 0;
        background: $cc-white;
        border-radius: 0;

        width: 100%;

        padding-left: u($rule/2);
        padding-right: u($rule/2);

        height: u($rule*1.5);
        line-height: u($rule*1.5);

        margin-bottom: 0;
    }

} 

.grecaptcha-badge {
    visibility: hidden;
}