.cta-inspiration {
	display: grid;

	@include mq($from: $viewport--md) {
		grid-template-columns: 1fr u($rule * 3);
		gap: u($rule/2);
	}

	&__images {
		position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;

		.cta-inspiration__images--thumb {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			transition: opacity 0.3s;
			opacity: 0;

			&.is-active {
				opacity: 1;
			}
		}
	}

	&__main {
		position: relative;

		.button__icon-block {
			position: absolute;

			right: 0;
			bottom: 0;
		}
	}

	&__thumbs {
		display: none;

		@include mq($from: $viewport--md) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			padding-bottom: u($rule * 2.5); // height button
		}
	}

	&__thumb {
		cursor: pointer;
		figure,
		img {
			aspect-ratio: 1/1;
		}
	}

	&__thumb + &__thumb {
		margin-top: u($rule/2);
	}
}
