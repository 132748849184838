.hamburger { 
    padding: u($rule/2);
    cursor: pointer;

    .line{
        width: 24px;
        height: 1px;
        background-color: $cc-white;
        display: block;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
    }

    &.is-active {
        
        transition: all 0.3s ease-in-out;
        transition-delay: 0.6s;
        transform: rotate(45deg);

        .line:nth-child(2){
            width: 0;
        }

        .line:nth-child(1), .line:nth-child(3) {
            transition-delay: 0.3s;

        }

        .line:nth-child(1){
            transform: translateY(6px);
        }
        
        .line:nth-child(3){
            transform: translateY(-6px) rotate(90deg);
        }
    }

}
