.link {
    position: relative;
    padding-bottom: u($rule/8);
    
    color: inherit;

    &:after { 
        content: '';
        position: absolute;
        bottom: 0;
        width: u($rule);
        display: block;
        height: 1px;
        left: calc(50% - #{u($rule/2)});
        background-color: transparent;
        transition: width 0.3s ease, left 0.3s ease, background-color 0.3s ease;
    }

    @media(hover) {
        &:hover {
            color: $cc-orange; 

            &:after { 
                background-color: $cc-orange;
                width: 100%;
                left: 0;
            }
        }
    }
}