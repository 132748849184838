.detail {

    margin-top: u($rule);
    margin-bottom: u($rule);

    &__breadcrumb {
        display: flex; 

        margin-bottom: u($rule/2);

        &__item {
            color: $cc-grey;

            a {
                color: $cc-grey;
            }
        }

        &__item + &__item {
            &:before {
                content: ' > ';
                padding-left: u($rule/2);
                padding-right: u($rule/2);
            }
        }
    }

    &__header {
        display: flex;
         
        max-height: u($rule*10);

        position: relative;
        z-index: -1;

        figure {
            flex: 1;
            max-width: 100%;

            img {
                height: 100%; 
            }
        }
    }

    &__frame {

        background: $cc-white;

        color: $cc-grey; 

        @include mq($from: $viewport--lg) {
            margin-top: -#{u($rule*2)};
            
            margin-left: u($rule*3);
            margin-right: u($rule*3);

            padding: u($rule*2) u($rule*3);
        }

        @include mq($from: $viewport--xxl) {
            margin-left: u($rule*5);
            margin-right: u($rule*5);

            padding: u($rule*2) u($rule*5);
        }


        &__intro {
            padding-top: u($rule*2);
            padding-bottom: u($rule*2);

        }

        &__content {
            border-top: 1px solid $cc-grey;

            padding-top: u($rule*2);
            padding-bottom: u($rule*2);
        }

        &__block + &__block {
            padding-top: u($rule * 2);
        }

        &__image {
            margin-left: -#{u($rule*5)};
            margin-right: -#{u($rule*5)};

            figure {
                width: 100%;
            }
        }

        &__quote {
            border-left: 1px solid $cc-orange; 

            padding-top: u($rule/2);
            padding-bottom: u($rule/2);
            padding-left: u($rule);

            font-size: 24px;
            line-height: 34px;
            color: $cc-black;

            font-family: $font-title;
            max-width: 350px;

        }

        &__brands {
            display: flex; 
            flex-wrap: wrap;

            gap: u($rule);

            > .figure {
                flex: 0 calc(33% - (#{u($rule / 3 * 2)}));

                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-height: 100px;
                    max-width: 200px;
                }
            }
        }

        &__title {
            text-transform: uppercase;
            font-weight: bold;

            margin-bottom: u($rule/2);

            color: $cc-orange;
        }


    }

    &__title {

        text-align: center; 
        max-width: u($rule*15);
        margin: 0 auto; 
        color: $cc-black; 

        margin-bottom: u($rule*2);

    }

    &__link {
        color: $cc-grey; 

    }

    &__footer {

    }
    
}