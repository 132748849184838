
.steps {
    counter-reset: step-counter;
    max-width: 650px;
    margin: 0 auto;

    &__header {
        margin-bottom: u($rule*2.5)
    }

    &__content {
    }
}

.step {
    
    + .step {
        margin-top: u($rule*1.5)
    }
    
    display: grid;
    gap: u($rule);
    
    @include mq($from: $viewport--md) {
        gap: u($rule*3);
        grid-template-columns: 1fr 3fr;
    }   

    margin-bottom: u($rule);
    counter-increment: step-counter;

    &__left {
    
    }

    &__image {
        position: relative;

        max-width: u($rule*5);
        max-height: u($rule*5);

        @include mq($until: $viewport--md--until) {
            margin: 0 auto; 
        }

        &:after {
            position: absolute;
            right: -#{u($rule)};
            bottom: -#{u($rule*.6)};

            content: counter(step-counter)". ";

            font-family: $font-title;
            font-size: u($heading-size-giga);
            line-height: .7;

            color: $cc-grey-light; 
        }
    }

    &__right {
        color: $cc-grey;

        display: flex;
        align-items: center;
        text-align: center;

        @include mq($from: $viewport--md) {
            text-align: left;
        }
    }
}