.footer {
    &__top {
        font-size: u($heading-size-6);
        line-height: 26px;

        display: grid; 

        grid-template-columns: 1fr;
        gap: u($rule);
        
        padding-top: 0;
        padding-bottom: u($rule*2);

        @include mq($from: $viewport--md) {
            padding-top: u($rule*2);
        }

        @include mq($from: $viewport--xl) {
            grid-template-columns: 175px auto auto auto;
            gap: u($rule*3);
        }

    }

    &__bottom {
        color: $cc-grey; 
        font-size: u($heading-size-milli);

        padding-top: u($rule);
        padding-bottom: u($rule);

        border-top: 1px solid $cc-grey-light;

        display: flex;
        flex-direction: column;
        gap: u($rule/3);
        
        @include mq($from: $viewport--md) {
            flex-direction: row;
            gap: 0;
            justify-content: space-between;
        }
    }

    &__logo {
        a {
            display: block;
            max-width: 80px;

            svg {
                max-width: 80px;
                height: auto;
            }
        }
    }

    &__slogan {
        font-family: $font-title;
        font-size: 18px;

        margin-top: 15px;
    }

    &__info {
        &__block {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            line-height: 31.5px;

            .link {
                margin: 0;
                padding-bottom: 0;
            }
        }
    } 

    &__nav {
        display: flex; 
        line-height: 31.5px;
        column-gap: u($rule);
        
        @include mq($until: $viewport--sm--until) {
            flex-wrap: wrap;
        }

        &__column {
            display: flex;
            flex-direction: column;
        }
    } 

    &__subscribe {
        &__title {
            font-family: $font-default;
            margin-bottom: u($rule);
            max-width: 220px;
        } 

        .form__row:last-child {
            justify-content: flex-end;
        }

        .form__action {
            margin-top: u($rule/2);
        }
    }

    &__disclaimer {
        .link + .link {
            margin-left: u($rule);
        }
    }


} 