.wrap {
    position: relative;
    // -ms-flex-positive: 1;
    // flex-grow: 1;
    flex: 1;
    margin: 0 auto;
    padding: 0 u($rule);
    max-width: 1600px;
    
    box-sizing: content-box;
    
    @include mq($from: $viewport--sm) {
        padding: 0 10vw;
    }

    &--no-wrap {
        @include mq($from: $viewport--sm) {
            padding: 0 u($rule);
        }
    }
    
    &--large {
        @include mq($from: $viewport--sm) {
            padding: 0 5vw;
        }

        @include mq($from: $viewport--xxl) {
            padding: 0 5vw;
        }

    }

    &--small {

        @include mq($from: $viewport--sm) {
            padding: 0 10vw;
        }

        @include mq($from: $viewport--lg) {
            padding: 0 15vw;
        }
        
    }

}
