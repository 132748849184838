.button-group {
    display: flex; 
    flex-wrap: wrap;

    .button {
        margin-right: u($rule);

        &:last-child {
            margin-right: 0;
        }
    }
}