.cta {
    background: $cc-grey-bg; 
    padding: u($rule);

    @include mq($from: $viewport--md) {
        padding: u($rule*2);
    }

    > * {
        max-width: 380px;
        margin: 0 auto;
    }

    .form__row:last-child {
        justify-content: flex-end;
    }

    .form__action {
        margin-top: u($rule/2);
    }

    .form {
        text-align: left;
    }


} 