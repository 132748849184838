.ContactPage {
    .map-container {
        display: grid;
        grid-template-columns: 1fr 40vw;
        grid-template-rows: 800px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include mq($from: 1024px) {            
            .contact__map {
                padding: 0;
                width: 100%;
                height: 100%;
    
                &__wrapper {
                    height: 100%;
                }
            }
        }        

        @include mq($until: 1400px) {
            grid-template-columns: 1fr 50vw;
            grid-template-rows: 650px;
        }


        @include mq($until: 1024px) {
            display: flex;
            flex-direction: column;

            img {
                height: 400px;
            }

            .contact__map {
                width: 100%;
                height: 100%;
            }
        }
    }
}