.blocks {

    &__header {
        padding-bottom: u($rule*2);

        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__content {
        display: flex; 
        flex-wrap: wrap;

        gap: u($rule);
    }

    &__item {
        flex: 0 100%;
        
        @include mq($from: $viewport--md) {
            flex: 0 calc(50% - (#{u($rule)}));
        }
        
        @include mq($from: $viewport--lg) {
            flex: 0 calc(25% - (#{u($rule / 4 * 3)}));
        }

        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        align-items: flex-start;
    } 
}