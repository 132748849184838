.brands {

    &__header {
        padding-bottom: u($rule*2);

        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__content {
        display: flex; 
        flex-wrap: wrap;
        
        justify-content: center;

        row-gap: u($rule*2);
        column-gap: u($rule);

        margin: 0 auto;

        max-width: u($rule*25);
    }

    &__item {
        max-width: u($rule*4);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}