.overlay {
    opacity: 0;

    transform: translate(-100vw, -100vh);
    transition: opacity .3s ease;
    transition-delay: .3s;
    
    .menu-open & {
        opacity: 1;

        transform: translate(0, 0);
    }
    
    position: fixed;
    
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    z-index: 999;
    
    padding: u($rule/2) u($rule);
    @include mq($from: $viewport--md) {
        padding: u($rule);
    }
    
    display: grid;

    grid-template-areas: "header" "content" "footer";
    grid-template-rows: auto 1fr auto;
    height: 100%;

    pointer-events: none;

    &__header {
        grid-area: header;

        &__logo {
            display: inline-block;

            pointer-events: all;
            max-width: 100px;
            height: auto;

            svg {
                height: u($rule*2);

                @include mq($from: $viewport--md) {
                    height: u($rule*3);
                }
            }
        }
    }
    
    &__content {
        grid-area: content;

        pointer-events: all;
        padding-right: u($rule*2);
        
        @include mq($until: $viewport--lg--until) {
            overflow: scroll;
            .overlay__wrap {
                padding-top: u($rule/2);
                padding-bottom: 0;
            }
        }
        
    }
    
    &__footer {
        grid-area: footer;
        pointer-events: all;

        .wrap {
            padding-top: u($rule/2);
            padding-bottom: 0;

            flex-wrap: wrap;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;

            .overlay__left .footer-nav {
                display: flex;
                flex-wrap: nowrap;
                .footer-nav__item + .footer-nav__item {
                    padding-left: u($rule/2);
                }
            }
        }
    }

    &__wrap {
        display: flex; 

        flex-direction: column;

        padding: u($rule) 0;

        @include mq($from: $viewport--lg) {
            padding: 0 unset;
            
            flex-direction: row;

            justify-content: space-between;
            align-items: center;
    
            height: 100%;
        }

    }

    &__left {

        max-width: 800px; 

    }

    &__right {

    }

}

.main-nav {

    display: flex;
    flex-wrap: wrap;

    &__item {

        font-family: $font-title;
        font-size: u($heading-size-3);
        line-height: u($heading-line-height-3);

        flex: 1 1 100%;

        padding: u($rule/4) 0;

        @include mq($from: $viewport--lg) {
            font-size: u($heading-size-1);
            line-height: u($heading-line-height-1);
    
            flex: 1 1 50%;
    
            padding: u($rule) 0;
        }
        
    }

    &__link {
        position: relative;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        transition: color .3s ease;
        color: $cc-grey;

        @media (hover) {
            &:hover {
                color: $cc-white;

                .icon svg {
                    transform: translateX(0);
                }

                .text {
                    transform: translateX(40px);
                }
            }
            
            &.active {
                color: $cc-white;
                
            }
        }

        .icon {
            position: absolute; 
            top: 0;
            left: 0;

            width: u($rule*1.25);
            height: 100%;
    
            display: flex; 
            justify-content: center;
            align-items: center;
                        
            overflow: hidden; 

            svg {
                transition: transform .3s ease;
                
                transform: translateX(-40px);

                #long-arrow-right {
                    fill: $cc-white;
                }
            }
            
        }
        
        .text {
            transform: translateX(0);
            transition: transform .3s ease;

            @include mq($until: $viewport--sm--until) {
                font-size: 26px;
                line-height: 28px;
            }

        }

    }

}

.side-nav {
    display: flex;
    
    flex-direction: column;

    padding-top: u($rule/2);
    
    @include mq($from: $viewport--lg) {
        flex-direction: row;
        
        padding-top: 0;
    }

    &__item + &__item {
        padding-left: 0;

        @include mq($from: $viewport--lg) {
            padding-left: u($rule);
        }

    }

    &__link {

        transition: color .3s ease;

        color: $cc-grey;

        &:hover {
            color: $cc-white;
        }
        
        &.active {
            color: $cc-white;
            
            &:after {
                content: '';

                display: inline-block;

                width: u($rule/4);
                height: u($rule/4);

                border-radius: 50%;
                background: $cc-orange;
            }
        }

    }
}

.footer-nav {

    display: flex;
    flex-wrap: wrap;

    &__item + &__item {
        padding-left: u($rule);
    }
    
    &__link {

        color: $cc-grey-dark; 
        text-transform: capitalize;
        
        &.active {
            text-decoration: underline;
        }
        
        &:hover {
            color: $cc-white; 
        }

    }
}
