.inspiration {

    &__header {
        margin-bottom: u($rule);

        display: flex;
        justify-content: center;
        
        text-align: center;

        > * {
            max-width: u($rule*20);
        }
    }
    
    &__filter {
        display: flex; 
        flex-wrap: wrap;
        gap: u($rule/2);

        justify-content: center;

        margin-bottom: u($rule*2);

        &__item {
            border: 1px solid $cc-black; 

            padding: u($rule/2) u($rule/1.5);
            line-height: 1;

            cursor: pointer;

            transition: border-color .3s ease, color .3s ease;

            &.active {
                background: $cc-black;
                color: $cc-white;
            }

            @media (hover) {

                &:not(.active):hover {
                    color: $cc-orange;
                    border-color: $cc-orange;
                }

            }
        }
    }

    &__content {

    }

    &__grid {

    }

    &__item {
        position: relative;

        @media (hover) {

            &:hover {
                &:after {

                    content: '';

                    position: absolute;

                    z-index: 1;
                    
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    background: rgba(0,0,0,.3);

                }

                &:before {

                    content: '';

                    display: block;
                    position: absolute;

                    z-index: 2;

                    width: u($rule/2);
                    height: u($rule/2);

                    top: 50%;
                    left: 50%;

                    transform: translate(-50%, -50%);

                    background-image: url('#{$path-img}svgs/icon-magnifier.svg');
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;

                }
            }
            
        }
    }
}

.inspiration__grid {
    display: grid;
    grid-template-columns: 1;
    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    gap: 16px;
}

.inspiration__grid__item{
    display: flex;
    flex-direction: column;
    grid-column: span 1 / span 1;
    gap: 16px;
}

.inspiration__item {
    width: 100%;

} 