.slideshow {
	&__image img {
		min-height: 350px !important;
		height: 55vh !important;
		max-height: 350px !important;

		@include mq($from: $viewport--md) {
			min-height: 350px !important;
			height: 75vh !important;
			max-height: 550px !important;
		}
	}

	.vertical-dots {
		@include mq($until: 601px) {
			position: relative;
			right: 0;
			top: 0;
			transform: translate(0px, 0px);
			display: flex;
			justify-content: center;
		}
	}
}
