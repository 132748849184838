.text-block {

    max-width: u($rule*15);

    &--center {
        text-align: center;
    }
    
    &__header {
        margin-bottom: u($rule);
    }
    
    &__content {
        margin-bottom: u($rule);
        color: $cc-grey;
    }

    &__footer {

    } 

    *:last-child {
        margin-bottom: 0;
    }
}