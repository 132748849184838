.button {
    $self: &; 

    display: inline-flex; 

    justify-content: center;
    align-items: center;

    &__icon {
        
        margin-right: u($rule/2); 
        
        .icon {
            display: block; 
            
            transition: transform .3s ease-in-out;
            transform: rotate(0);
        }

    } 

    &__icon-block {

        background: $cc-grey-bg; 
        
        width: u($rule*1.25);
        height: u($rule*1.25);
        
        margin-right: u($rule); 
        
        overflow: hidden; 
        
        display: flex; 
        justify-content: center;
        align-items: center;
        
        svg path {
            fill: $cc-orange; 
        }
        
        &.icon-white {
            background: $cc-white; 
        }

        &.big {

            width: u($rule*2.5);
            height: u($rule*2.5);

            margin-right: 0;

        }

    }
    
    @media (hover) {
        &:hover {
            #{$self}__icon .icon {
                transform: rotate(360deg);
            } 

            #{$self}__icon-block .icon {
                animation: animateArrow 1s;
            }

            #{$self}__icon-block.big .icon {
                animation: animateArrowBig 1s;
            }
        }
    }

    @keyframes animateArrow {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(150%);
        }
        51% {
            transform: translateX(-150%);
        } 
        100% {
            transform: translateX(0);
        }
    }

    @keyframes animateArrowBig {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(250%);
        }
        51% {
            transform: translateX(-250%);
        } 
        100% {
            transform: translateX(0);
        }
    }

}