.block {

    &__image {
        width: 100%;
        
        figure {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }

    &__content {
        color: $cc-grey;

        padding-top: u($rule);
    }
}