/* Link */
a {
    @include transition(#{background-color, color});
    color: $link-color;
    text-decoration: $link-text-decoration;

    @media(hover) {
        &:hover,
        &:focus,
        &:active {
            color: $link-color--hover;
            text-decoration: $link-text-decoration--hover;
        }
    }
}
