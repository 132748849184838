[class^='title'], [class*=' title']{
    font-family: $font-title;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0;

    overflow-wrap: break-word;
    word-wrap: break-word;
    
    @include mq($until: $viewport--md--until) {
        hyphens: auto;
        
        br {
            display: none;
        }
    }
}

.title {
    font-size: u($heading-size-1);
    line-height: u($heading-line-height-1);
}

.title--beta {
    font-size: u($heading-size-2);
    line-height: u($heading-line-height-2);
}

.title--charlie {
    font-size: u($heading-size-3);
    line-height: u($heading-line-height-3);
}

.title--delta {
    font-size: u($heading-size-4);
    line-height: u($heading-line-height-4);
}

.title--echo {
    font-size: u($heading-size-5);
    line-height: u($heading-line-height-5);
}

.title--foxtrot {
    font-size: u($heading-size-6);
    line-height: u($heading-line-height-6);
}