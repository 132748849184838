.jobs {

    max-width: u($rule*35);

    @include mq($from: $viewport--md) {
        padding-top: u($rule*3);
        margin: 0 auto;
    }

    &__item {
        display: flex;
        justify-content: space-between;

        border-top: 1px solid $cc-grey-bg; 

        padding-top: u($rule*0.75);
        padding-bottom: u($rule*0.75);

        &:last-child {
            border-bottom: 1px solid $cc-grey-bg;
        }

        &__left {

        }

        &__right {

        }

        @media (hover) {
            &:hover {
                color: $cc-orange; 

                svg {
                    fill: $cc-orange;
                }
            }
        }

    }
}