.nav {
    $self: &; 

    display: flex;
    font-size: u($heading-size-6);
    font-weight: $weight-medium;
    flex-wrap: wrap;

    &__item + &__item {
        margin-left: u($rule);
    }

    &__link {
        position: relative;

        padding-top: u($rule/4);
        padding-bottom: u($rule/4);

        &:after { 
            content: '';
            position: absolute;
            bottom: 0;
            width: u($rule);
            display: block;
            height: 1px;
            left: calc(50% - #{u($rule/2)});
            background-color: transparent;
            transition: width 0.3s ease, left 0.3s ease, background-color 0.3s ease;
        }

        @media(hover) {
            &:hover {
                color: $cc-orange; 

                &:after { 
                    background-color: $cc-orange;
                    width: 100%;
                    left: 0;
                }
            }
        }
    }


} 