.wysiwyg {
    margin-bottom: u($rule);

    &--center {
        text-align: center;
    }

    &--no-margin {
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }
    }
    
    a { 
        @include transition(#{background-color, color});
        color: inherit;
        text-decoration: underline;

        @media (hover) {
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }
    }

    strong {
        font-weight: bold;
        color: $cc-black;
    }

    .button {
        text-decoration: none;
    }

    ol,
    ul {
        margin-bottom: u($spacing-unit);
        padding-left: u($list-gutter--l);

        li {
            margin-bottom: u(5);
            list-style-type: '\2014';
        }

        ol,
        ul {
            margin-top: u(4);
            margin-bottom: u(4);
        }
    }

    // Unordered List
    ul {
        list-style-type: disc;
    }

    ul ul {
        list-style-type: circle;
    }

    ul ul ul {
        list-style-type: square;
    }

    // Ordered List
    ol {
        list-style-type: decimal;
    }

    ol ol {
        list-style-type: upper-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    ol ol ol ol {
        list-style-type: lower-alpha;
    }

    // Definition List
    dt {
        font-weight: 700;
    }

    strong {
        font-weight: bold;
    }

    p {
        margin-bottom: u($rule/2);
    }
    
    
    /* Heading */
    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
        font-style: normal;
        margin-bottom: u($rule/2);
    }

    h1 {
        @include font-size($heading-size-1, false);
    }

    h2 {
        @include font-size($heading-size-2, false);
    }
    
    h3 {
        @include font-size($heading-size-3, false);
    }
    
    h4 {
        @include font-size($heading-size-4, false);
    }
    
    h5 {
        @include font-size($heading-size-5, false);
    }

    &:last-child, > *:last-child {
        margin-bottom: 0;
    }

    table td {
        padding-right: u($rule); 
    } 

}