.contact {
    display: grid; 
    gap: u($rule);

    @include mq($from: $viewport--lg) {
        grid-template-columns: 1fr 1fr;
    }
    
    &__content {
        margin-top: u($rule);
    }

    &__form {
        .form {
            margin-top: 0;
        }
    }

    &__map {
        &__wrapper {
            height: u($rule*10);

            @include mq($from: $viewport--md) {
                height: u($rule*20);
            }
        }
    }    
}