.categories-grid {

    display: grid; 
    gap: u($rule*1.5);

    grid-template-areas: 
        "c-1"
        "c-2"
        "c-3"
        "c-4"
        "c-5";

    @include mq($from: $viewport--md) {
        grid-template-areas: 
            "c-1 c-2"
            "c-3 c-4"
            "c-3 c-5";

        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }

    @include mq($from: $viewport--lg) {
        grid-template-areas: "c-1 c-3 c-4"
                        "c-2 c-3 c-5";

        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {

        display: flex; 
        
        > * {
            flex: 1;
        }

        img {
            aspect-ratio: 4 / 3;
        }

        &--c-1 {
            grid-area: c-1;
        }

        &--c-2 {
            grid-area: c-2;
        }

        &--c-3 {
            grid-area: c-3;


            img {
                aspect-ratio: 3 / 4;
            }
        }

        &--c-4 {
            grid-area: c-4;
        }

        &--c-5 {
            grid-area: c-5;
        }

        &--cta {
            align-items: stretch;
        }

        @media (hover) {
            
            &:hover {
                .figure__label {
                    bottom: 0;
                }
            }

        }


    }

    &__cta {
        background: $cc-grey-bg; 
        
        position: relative;

        padding: u($rule) u($rule) u($rule*2.5) u($rule);

        .button__icon-block {
            position: absolute;

            right: 0;
            bottom: 0;
        }
    }

}