/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-grey-light: #EAE7E7;
$cc-grey: #BCBBBB;
$cc-grey-dark: #6A6A6A;
$cc-grey-bg: #F9F9F9;
$cc-black:#000000;
$cc-orange: var(--tegel-black); 