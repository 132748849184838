
// ============================================
// VENDOR: SLICK
// ============================================

@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

// FIX 404 LOADER.GIF
.slick-list {
    .slick-loading & {
        background: none;
    }
}

// FIX ERROR FONT 
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: url("/fonts/slick/slick.eot");
        src: url("/fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("/fonts/slick/slick.woff") format("woff"), url("/fonts/slick/slick.ttf") format("truetype"), url("/fonts/slick/slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

// CUSTOM ARROWS 
.slick-prev, .slick-next {
    width: u(8);
    height: u(8);
    
    &:before {
        content: '';

        display: block;
        width: u(8);
        height: u(8);

        border-bottom: 1px solid $cc-white;
        border-left: 1px solid $cc-white;
    }
}

.slick-prev {
    &:before {
        transform:rotate(45deg);
    }
}

.slick-next {
    &:before {
        transform:rotate(225deg);
    }
}

// MAKE SLIDES EQUAL HEIGHT AND CENTER TEXT 
.testimonials__slideshow {

    .slick-track
    {
        display: flex !important;
    }
    
    .slick-slide
    {
        height: inherit !important;
        align-self: center;
    }
    
}

//VERTICAL DOTS
.vertical-dots
{
    right: -40px;
    list-style: none;
    display: block;
    position: absolute;
    
    top: 50%;
    transform: translateY(-50%);
    
    text-align: right;
}
.vertical-dots li
{
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.vertical-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus
{
    outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before
{
    opacity: 1;
}
.vertical-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    color: $cc-grey;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before
{
    font-size: 10px;
    color: $cc-orange;
}