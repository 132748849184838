.figure {
    $self: &;

    max-width: 650px;
    position: relative;
    
    &__image {
        position: relative;

        // aspect-ratio: 16 / 9;

        // &--portrait {
        //     aspect-ratio: 9 / 16;
        // }
    }

    &__label {
        position: absolute;

        bottom: -7px;
        left: 0;

        background: $cc-white;
        color: $cc-black;

        font-weight: 500;
        
        padding: u($rule/1.5) u($rule/1.5) 0 0;

        transition: bottom .3s ease;
        
    }
    
    &__copy {
        font-size: u(12);
        font-weight: 500;
        
        color: $cc-grey;

        text-transform: uppercase;
        text-align: right;

        padding-top: u($rule/4);
    }

    &--rounded {
        #{$self}__image {
            aspect-ratio: 1/1;
            border-radius:100%; 

        }
    }
}