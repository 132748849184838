@charset "UTF-8";
/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: basic */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: 'DM Serif Display';
  src: url("/fonts/DMSerifDisplay-Regular.woff2") format("woff2"), url("/fonts/DMSerifDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'DM Serif Display';
  src: url("/fonts/DMSerifDisplay-Italic.woff2") format("woff2"), url("/fonts/DMSerifDisplay-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-BoldItalic.woff2") format("woff2"), url("/fonts/Gilroy-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-Medium.woff2") format("woff2"), url("/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-MediumItalic.woff2") format("woff2"), url("/fonts/Gilroy-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-LightItalic.woff2") format("woff2"), url("/fonts/Gilroy-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-Bold.woff2") format("woff2"), url("/fonts/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-Light.woff2") format("woff2"), url("/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-ThinItalic.woff2") format("woff2"), url("/fonts/Gilroy-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-RegularItalic.woff2") format("woff2"), url("/fonts/Gilroy-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-Thin.woff2") format("woff2"), url("/fonts/Gilroy-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("/fonts/Gilroy-Regular.woff2") format("woff2"), url("/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Functions: Units */
/* Functions: SVG */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: String */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Hover */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  letter-spacing: 0.01em; }

*, *::before, *::after {
  box-sizing: border-box; }

* {
  margin: 0; }

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased; }

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%; }

input, button, textarea, select {
  font: inherit;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none; }

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  word-wrap: break-word; }
  @media screen and (max-width: 47.9375em) {
    p, h1, h2, h3, h4, h5, h6 {
      hyphens: auto; } }

#root, #__next {
  isolation: isolate; }

ol, ul {
  list-style: none; }

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, [data-aos^=fade][data-aos^=fade], [data-aos^=zoom][data-aos^=zoom] {
    opacity: 1;
    transform: none !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-loading .slick-list {
  background: none; }

@font-face {
  font-family: "slick";
  src: url("/fonts/slick/slick.eot");
  src: url("/fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("/fonts/slick/slick.woff") format("woff"), url("/fonts/slick/slick.ttf") format("truetype"), url("/fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

.slick-prev, .slick-next {
  width: 8px;
  height: 8px; }
  .slick-prev:before, .slick-next:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid white;
    border-left: 1px solid white; }

.slick-prev:before {
  transform: rotate(45deg); }

.slick-next:before {
  transform: rotate(225deg); }

.testimonials__slideshow .slick-track {
  display: flex !important; }

.testimonials__slideshow .slick-slide {
  height: inherit !important;
  align-self: center; }

.vertical-dots {
  right: -40px;
  list-style: none;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: right; }

.vertical-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer; }

.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.vertical-dots li button:hover,
.vertical-dots li button:focus {
  outline: none; }

.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1; }

.vertical-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  color: #BCBBBB;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.vertical-dots li.slick-active button:before {
  font-size: 10px;
  color: var(--tegel-black); }

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0; }

.glightbox-container.inactive {
  display: none; }

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden; }

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0; }

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative; }

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999; }

.glightbox-container .gslide-inner-content {
  width: 100%; }

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh; }

.glightbox-container .ginner-container.gvideo-container {
  width: 100%; }

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important; }

.gslide iframe, .gslide video {
  outline: 0 !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto; }

.gslide:not(.current) {
  pointer-events: none; }

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: 0;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px; }

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto; }

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%; }

.gslide-image img.zoomable {
  position: relative; }

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none; }

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important; }

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none; }

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto; }

.gslide-video::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none; }

.gslide-video.playing::before {
  display: none; }

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh; }

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important; }

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto; }

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%; }

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none; }

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1; }

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%; }

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto; }

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.desc-bottom .gslide-media, .desc-top .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%; }

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%; }

.gslide-description.description-bottom, .gslide-description.description-top {
  margin: 0 auto;
  width: 100%; }

.gslide-description p {
  margin-bottom: 12px; }

.gslide-description p:last-child {
  margin-bottom: 0; }

.zoomed .gslide-description {
  display: none; }

.glightbox-button-hidden {
  display: none; }

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
  padding-bottom: 50px; }

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em; }

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1; }

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700; }

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit; }

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: .4; }

.gdesc-open .gslide-media {
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
  opacity: .4; }

.gdesc-open .gdesc-inner {
  padding-bottom: 30px; }

.gdesc-closed .gslide-media {
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
  opacity: 1; }

.greset {
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.gabsolute {
  position: absolute; }

.grelative {
  position: relative; }

.glightbox-desc {
  display: none !important; }

.glightbox-open {
  overflow: hidden; }

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader .8s infinite linear;
  animation: lightboxLoader .8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%; }

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity; }

.glightbox-mobile .goverlay {
  background: #000; }

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.gclose svg, .gnext svg, .gprev svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0; }

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: .1; }

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff; }

.gbtn.focused {
  outline: 2px solid #0f3d81; }

iframe.wait-autoplay {
  opacity: 0; }

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important; }

.glightbox-clean .gslide-description {
  background: #fff; }

.glightbox-clean .gdesc-inner {
  padding: 22px 20px; }

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: 400;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em; }

.glightbox-clean .gslide-desc {
  font-size: .86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em; }

.glightbox-clean .gslide-video {
  background: #000; }

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px; }

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff; }

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px; }

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px; }

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute; }

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto; }

.glightbox-clean .gclose:hover {
  opacity: 1; }

.gfadeIn {
  -webkit-animation: gfadeIn .5s ease;
  animation: gfadeIn .5s ease; }

.gfadeOut {
  -webkit-animation: gfadeOut .5s ease;
  animation: gfadeOut .5s ease; }

.gslideOutLeft {
  -webkit-animation: gslideOutLeft .3s ease;
  animation: gslideOutLeft .3s ease; }

.gslideInLeft {
  -webkit-animation: gslideInLeft .3s ease;
  animation: gslideInLeft .3s ease; }

.gslideOutRight {
  -webkit-animation: gslideOutRight .3s ease;
  animation: gslideOutRight .3s ease; }

.gslideInRight {
  -webkit-animation: gslideInRight .3s ease;
  animation: gslideInRight .3s ease; }

.gzoomIn {
  -webkit-animation: gzoomIn .5s ease;
  animation: gzoomIn .5s ease; }

.gzoomOut {
  -webkit-animation: gzoomOut .5s ease;
  animation: gzoomOut .5s ease; }

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes gfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes gfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes gfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes gfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0); }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0); }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden; } }

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden; } }

@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0; } }

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0; } }

@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

@-webkit-keyframes gzoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes gzoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%; }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab; }
  .gslide-inline {
    max-height: 95vh; }
  .gslide-external {
    max-height: 100vh; }
  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px; }
  .glightbox-open {
    height: auto; }
  .goverlay {
    background: rgba(0, 0, 0, 0.92); }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65); }
  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto; }
  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: rgba(0, 0, 0, 0.32); }
  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: rgba(0, 0, 0, 0.7); }
  .glightbox-clean .gprev {
    top: 45%; }
  .glightbox-clean .gnext {
    top: 45%; } }

@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: .7;
    right: 20px; } }

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000; } }

.progressive {
  display: block;
  outline: none;
  overflow: hidden;
  position: relative; }

.progressive img {
  border: 0;
  display: block;
  height: auto;
  max-width: none;
  width: 100%; }

.progressive img.preview {
  filter: blur(2vw);
  transform: scale(1.05); }

.progressive img.reveal {
  animation: progressiveReveal 1s linear;
  left: 0;
  position: absolute;
  top: 0; }

@keyframes progressiveReveal {
  0% {
    opacity: 0;
    transform: scale(1.05); }
  to {
    opacity: 1;
    transform: scale(1); } }

/* HR */
hr {
  margin-top: 24px;
  margin-bottom: 23px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }
  figure > img.img--cover {
    object-fit: cover;
    width: 100%;
    min-height: 100%;
    max-height: 850px; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #000000;
  text-decoration: none; }
  @media (hover) {
    a:hover, a:focus, a:active {
      color: #000000;
      text-decoration: none; } }

/* List */
.list {
  margin-bottom: 24px; }
  .list li {
    padding-left: 24px;
    margin-bottom: 5px;
    list-style-type: none;
    position: relative; }
    .list li strong {
      font-weight: bold; }
  .list ol,
  .list ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul.list {
  list-style-type: none; }
  ul.list li:before {
    content: "\2022";
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
    color: var(--font-color);
    font-size: 25px;
    font-weight: bold;
    display: inline-block;
    width: 25px; }

/* Selection */
::selection {
  color: white;
  background-color: var(--tegel-black); }

.wrap {
  position: relative;
  flex: 1;
  margin: 0 auto;
  padding: 0 32px;
  max-width: 1600px;
  box-sizing: content-box; }
  @media screen and (min-width: 37.5em) {
    .wrap {
      padding: 0 10vw; } }
  @media screen and (min-width: 37.5em) {
    .wrap--no-wrap {
      padding: 0 32px; } }
  @media screen and (min-width: 37.5em) {
    .wrap--large {
      padding: 0 5vw; } }
  @media screen and (min-width: 120em) {
    .wrap--large {
      padding: 0 5vw; } }
  @media screen and (min-width: 37.5em) {
    .wrap--small {
      padding: 0 10vw; } }
  @media screen and (min-width: 64em) {
    .wrap--small {
      padding: 0 15vw; } }

.wysiwyg {
  margin-bottom: 32px;
  /* Heading */ }
  .wysiwyg--center {
    text-align: center; }
  .wysiwyg--no-margin {
    margin-bottom: 0; }
    .wysiwyg--no-margin p {
      margin-bottom: 0; }
  .wysiwyg a {
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    color: inherit;
    text-decoration: underline; }
    @media (hover) {
      .wysiwyg a:hover, .wysiwyg a:focus, .wysiwyg a:active {
        text-decoration: none; } }
  .wysiwyg strong {
    font-weight: bold;
    color: #000000; }
  .wysiwyg .button {
    text-decoration: none; }
  .wysiwyg ol,
  .wysiwyg ul {
    margin-bottom: 24px;
    padding-left: 24px; }
    .wysiwyg ol li,
    .wysiwyg ul li {
      margin-bottom: 5px;
      list-style-type: '\2014'; }
    .wysiwyg ol ol,
    .wysiwyg ol ul,
    .wysiwyg ul ol,
    .wysiwyg ul ul {
      margin-top: 4px;
      margin-bottom: 4px; }
  .wysiwyg ul {
    list-style-type: disc; }
  .wysiwyg ul ul {
    list-style-type: circle; }
  .wysiwyg ul ul ul {
    list-style-type: square; }
  .wysiwyg ol {
    list-style-type: decimal; }
  .wysiwyg ol ol {
    list-style-type: upper-alpha; }
  .wysiwyg ol ol ol {
    list-style-type: lower-roman; }
  .wysiwyg ol ol ol ol {
    list-style-type: lower-alpha; }
  .wysiwyg dt {
    font-weight: 700; }
  .wysiwyg strong {
    font-weight: bold; }
  .wysiwyg p {
    margin-bottom: 16px; }
  .wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
    font-weight: 500;
    font-style: normal;
    margin-bottom: 16px; }
  .wysiwyg h1 {
    font-size: 48px;
    line-height: 1.2; }
  .wysiwyg h2 {
    font-size: 36px;
    line-height: 1.2; }
  .wysiwyg h3 {
    font-size: 30px;
    line-height: 1.2; }
  .wysiwyg h4 {
    font-size: 19px; }
  .wysiwyg h5 {
    font-size: 15px; }
  .wysiwyg:last-child, .wysiwyg > *:last-child {
    margin-bottom: 0; }
  .wysiwyg table td {
    padding-right: 32px; }

[class^='title'], [class*=' title'] {
  font-family: "DM Serif Display", Georgia, "Times New Roman", Times, serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0;
  overflow-wrap: break-word;
  word-wrap: break-word; }
  @media screen and (max-width: 47.9375em) {
    [class^='title'], [class*=' title'] {
      hyphens: auto; }
      [class^='title'] br, [class*=' title'] br {
        display: none; } }

.title {
  font-size: 48px;
  line-height: 55px; }

.title--beta {
  font-size: 36px;
  line-height: 40px; }

.title--charlie {
  font-size: 30px;
  line-height: 34px; }

.title--delta {
  font-size: 19px;
  line-height: 24px; }

.title--echo {
  font-size: 15px;
  line-height: 24px; }

.title--foxtrot {
  font-size: 14px;
  line-height: 22px; }

/* Paragraph */
p {
  margin-bottom: 24px; }

.Lead {
  font-size: 16.875px; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: "Gilroy", Helvetica, Arial, sans-serif;
  height: 100%;
  scroll-behavior: smooth;
  font-size: 15px;
  line-height: 24px; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  position: relative;
  overflow-x: hidden; }

.hide {
  display: none; }

.inactive {
  opacity: .4;
  pointer-events: none; }

.menu-open {
  overflow: hidden;
  height: 100%; }

.form {
  position: relative;
  margin-top: 32px; }
  .form .form__row {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media screen and (min-width: 48em) {
      .form .form__row {
        flex-direction: row;
        justify-content: space-between; } }
    @media screen and (min-width: 64em) and (max-width: 79.9375em) {
      .footer .form .form__row {
        flex-direction: column; } }
    .form .form__row + .form__row {
      margin-top: 16px; }
      .footer .form .form__row + .form__row {
        margin-top: 0; }
  .form .form__field {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; }
    .form .form__field label, .form .form__field input, .form .form__field textarea, .form .form__field select {
      padding: 0;
      background: white;
      border-radius: 0; }
    .form .form__field.form__checkbox .form__label {
      font-family: "Gilroy", Helvetica, Arial, sans-serif;
      font-size: 90%;
      position: relative;
      line-height: 1.5; }
      .form .form__field.form__checkbox .form__label .link:before {
        content: none; }
    .form .form__field.form__checkbox input[type=checkbox] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
      .form .form__field.form__checkbox input[type=checkbox] ~ .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid #EAE7E7;
        z-index: 0; }
      .form .form__field.form__checkbox input[type=checkbox]:checked ~ .checkmark {
        background-color: var(--tegel-black);
        border-color: var(--tegel-black); }
      .form .form__field.form__checkbox input[type=checkbox] ~ label {
        padding-left: 25px;
        cursor: pointer; }
    .form .form__field.form__checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none; }
    .form .form__field.form__checkbox input:checked ~ .checkmark:after {
      display: block; }
    .form .form__field.form__checkbox .checkmark:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    .form .form__field.form__action {
      flex: 0;
      flex-direction: unset;
      justify-content: flex-end; }
    @media screen and (min-width: 48em) {
      .form .form__field.form__action .button {
        align-self: flex-end; } }
    .form .form__field:not(.form__checkbox) .form__label {
      position: absolute;
      left: 16px;
      top: 1px;
      z-index: 10;
      height: 46px;
      line-height: 46px;
      color: #BCBBBB;
      transition: left 150ms ease-out, right 150ms ease-out; }
      .form .form__field:not(.form__checkbox) .form__label.active {
        left: unset;
        right: 16px; }
    .form .form__field .form__input {
      outline: none;
      width: 100%;
      padding-left: 16px;
      padding-right: 64px;
      height: 48px;
      line-height: 48px;
      border: 1px solid #EAE7E7; }
      .form .form__field .form__input--hidden {
        display: none; }
    .form .form__field textarea.form__input {
      height: 160px;
      padding-top: 16px;
      line-height: unset;
      resize: vertical; }
    .form .form__field .form__submit {
      cursor: pointer;
      padding-bottom: 2px;
      border-bottom: 1px solid #000000;
      transition: border-bottom-color .3s ease;
      margin-top: 16px; }
      @media (hover) {
        .form .form__field .form__submit:hover {
          border-bottom-color: var(--tegel-black); } }
      @media screen and (min-width: 48em) {
        .form .form__field .form__submit {
          margin-top: 0; } }
    .form .form__field.form__wysiwyg {
      display: flex;
      color: #BCBBBB;
      padding-right: 0; }
      @media screen and (min-width: 48em) {
        .form .form__field.form__wysiwyg {
          padding-right: 64px; } }
      .form .form__field.form__wysiwyg a {
        text-decoration: underline; }
  .form .ff-errors, .form .form__error, .form .freeform-form-has-errors, .form .ff-form-errors, .form .ff-form-success {
    color: var(--tegel-black);
    font-size: 90%;
    font-style: italic;
    margin-top: 16px;
    margin-bottom: 16px; }
  .form .file-container {
    display: flex; }
    .form .file-container .fake-file {
      flex: 1;
      margin-right: 16px;
      color: #BCBBBB;
      border: 1px solid #EAE7E7; }
    .form .file-container .add-file-btn {
      flex: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #EAE7E7; }
  .form .fake-file, .form .add-file-btn {
    padding: 0;
    background: white;
    border-radius: 0;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    height: 48px;
    line-height: 48px;
    margin-bottom: 0; }

.grecaptcha-badge {
  visibility: hidden; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xxl {
    max-width: 1960px;
    padding-right: 100px;
    padding-left: 100px; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1100px; }
  .Container--sm {
    max-width: 1000px; }
  .Container--xs {
    max-width: 640px; }
  @media screen and (min-width: 111.25em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 360px;
        padding-left: 360px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }
  @media screen and (min-width: 20em) and (max-width: 37.5625em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 20px;
        padding-left: 20px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas: "header" "main" "socials" "footer"; }
  @media screen and (min-width: 48em) {
    body {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: "header" "main" "footer"; } }

.header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: 80px; }

.footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }

.main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .main > .container {
    padding-top: 24px;
    padding-bottom: 24px; }
    @media screen and (min-width: 48em) {
      .main > .container {
        grid-gap: 48px; }
        .main > .container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .main > .container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .main > .container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .side {
      margin-top: 24px; } }
  @media screen and (min-width: 48em) {
    .side--left {
      grid-area: left; }
    .side--right {
      grid-area: right; } }

.content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 4px 8px 4px 8px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -12px -12px 12px;
          padding: 12px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.nav {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  flex-wrap: wrap; }
  .nav__item + .nav__item {
    margin-left: 32px; }
  .nav__link {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px; }
    .nav__link:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 32px;
      display: block;
      height: 1px;
      left: calc(50% - 16px);
      background-color: transparent;
      transition: width 0.3s ease, left 0.3s ease, background-color 0.3s ease; }
    @media (hover) {
      .nav__link:hover {
        color: var(--tegel-black); }
        .nav__link:hover:after {
          background-color: var(--tegel-black);
          width: 100%;
          left: 0; } }

/* Cookie */
.CookieConsent {
  background-color: white;
  color: #000000;
  transition: transform .2s ease, visibility .2s ease, max-width .2s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  z-index: 900;
  max-width: 380px;
  right: 20px;
  bottom: 20px;
  padding: 30px;
  left: 20px; }
  .CookieConsent a {
    text-decoration: underline; }
  .CookieConsent.customization {
    pointer-events: none; }
    .CookieConsent.customization:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(31, 30, 30, 0.6); }
  .CookieConsent-custom {
    background-color: white;
    color: #000000;
    width: 500px;
    padding: 20px 25px;
    z-index: 1000;
    max-height: 100%;
    overflow: auto; }
    .CookieConsent-custom.hidden {
      display: none; }
    .CookieConsent-custom .title {
      font-family: "DM Serif Display", Georgia, "Times New Roman", Times, serif;
      margin-bottom: 10px; }
    .CookieConsent-custom .text {
      font-size: 12px; }
    .CookieConsent-custom .accept-compliance-cookie {
      margin-top: 10px; }
    .CookieConsent-custom .choices-container {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #BCBBBB; }
    .CookieConsent-custom .choices .checkbox + .checkbox {
      margin-top: 20px; }
    .CookieConsent-custom .choices .checkbox.required .input {
      pointer-events: none;
      opacity: .6;
      filter: grayscale(1); }
    .CookieConsent-custom .choices .checkbox .input .fake-box {
      width: 15px;
      height: 15px;
      border: 2px solid #BCBBBB;
      transition: border-color .2s ease, background-color .2s ease; }
    .CookieConsent-custom .choices .checkbox .input label {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .CookieConsent-custom .choices .checkbox .input label > .fake-box {
        margin-right: 5px; }
      .CookieConsent-custom .choices .checkbox .input label span {
        font-size: 13px;
        line-height: 1; }
    .CookieConsent-custom .choices .checkbox .input input {
      display: none; }
      .CookieConsent-custom .choices .checkbox .input input:checked ~ label > .fake-box {
        border-color: #000000;
        background-color: #000000; }
    .CookieConsent-custom .choices .checkbox .info {
      display: none;
      font-size: 12px;
      margin-top: 5px;
      color: #6A6A6A;
      margin-left: 20px;
      font-style: italic; }
      .CookieConsent-custom .choices .checkbox .info-container {
        margin-top: 5px; }
      .CookieConsent-custom .choices .checkbox .info-trigger {
        font-size: 13px;
        border-bottom: 1px solid #BCBBBB;
        cursor: pointer;
        transition: opacity .2s ease; }
        @media (hover) {
          .CookieConsent-custom .choices .checkbox .info-trigger:hover {
            opacity: .7; } }
      .CookieConsent-custom .choices .checkbox .info-checkbox {
        display: none; }
        .CookieConsent-custom .choices .checkbox .info-checkbox:checked ~ .info {
          display: flex; }
        .CookieConsent-custom .choices .checkbox .info-checkbox:checked ~ .info-trigger {
          display: none; }
    .CookieConsent-custom .customize-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px; }
      .CookieConsent-custom .customize-buttons .customize-back-btn {
        margin-top: 10px;
        cursor: pointer;
        font-size: 13px; }
  .CookieConsent-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(31, 30, 30, 0.6);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s ease;
    pointer-events: none; }
    .CookieConsent-container.is-active {
      opacity: 1;
      pointer-events: all; }
  .CookieConsent .normal-buttons, .CookieConsent .customize-buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%; }
    .CookieConsent .normal-buttons.hidden, .CookieConsent .customize-buttons.hidden {
      display: none; }
  .CookieConsent .customize-container {
    margin-top: 30px; }
    .CookieConsent .customize-container.hidden {
      display: none; }

.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  visibility: hidden; }

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 16px;
  font-weight: 400; }

.Accept, .customize-btn {
  cursor: pointer;
  line-height: 1;
  width: 100%;
  border: 1px solid var(--tegel-black);
  color: white;
  font-size: 13px;
  padding: 10px 12px;
  background-color: var(--tegel-black);
  text-align: center;
  transition: background-color .2s ease, color .2s ease; }
  @media (hover) {
    .Accept:hover, .customize-btn:hover {
      color: #000000;
      background-color: white; } }

.customize-btn {
  margin-top: 10px; }

.header {
  display: flex;
  justify-content: space-between; }
  .header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    max-width: 100px; }
  .header__nav {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px; }
    .header__nav .active {
      color: var(--tegel-black); }
    @media screen and (max-width: 63.9375em) {
      .header__nav {
        display: none; } }
  .header__hamburger {
    width: 80px;
    height: 80px;
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .5s ease-in-out;
    z-index: 999; }
    .menu-open .header__hamburger {
      box-shadow: 0 0 0 100vw #000000, 0 0 0 100vh #000000;
      border-radius: 0; }

.section {
  padding-top: 96px;
  padding-bottom: 96px; }
  @media screen and (min-width: 64em) {
    .section {
      padding-top: 160px;
      padding-bottom: 160px; } }
  .section + .section {
    padding-top: 0; }

.hamburger {
  padding: 16px;
  cursor: pointer; }
  .hamburger .line {
    width: 24px;
    height: 1px;
    background-color: white;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out; }
  .hamburger.is-active {
    transition: all 0.3s ease-in-out;
    transition-delay: 0.6s;
    transform: rotate(45deg); }
    .hamburger.is-active .line:nth-child(2) {
      width: 0; }
    .hamburger.is-active .line:nth-child(1), .hamburger.is-active .line:nth-child(3) {
      transition-delay: 0.3s; }
    .hamburger.is-active .line:nth-child(1) {
      transform: translateY(6px); }
    .hamburger.is-active .line:nth-child(3) {
      transform: translateY(-6px) rotate(90deg); }

.button-group {
  display: flex;
  flex-wrap: wrap; }
  .button-group .button {
    margin-right: 32px; }
    .button-group .button:last-child {
      margin-right: 0; }

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .button__icon {
    margin-right: 16px; }
    .button__icon .icon {
      display: block;
      transition: transform .3s ease-in-out;
      transform: rotate(0); }
  .button__icon-block {
    background: #F9F9F9;
    width: 40px;
    height: 40px;
    margin-right: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
    .button__icon-block svg path {
      fill: var(--tegel-black); }
    .button__icon-block.icon-white {
      background: white; }
    .button__icon-block.big {
      width: 80px;
      height: 80px;
      margin-right: 0; }
  @media (hover) {
    .button:hover .button__icon .icon {
      transform: rotate(360deg); }
    .button:hover .button__icon-block .icon {
      animation: animateArrow 1s; }
    .button:hover .button__icon-block.big .icon {
      animation: animateArrowBig 1s; } }

@keyframes animateArrow {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(150%); }
  51% {
    transform: translateX(-150%); }
  100% {
    transform: translateX(0); } }

@keyframes animateArrowBig {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(250%); }
  51% {
    transform: translateX(-250%); }
  100% {
    transform: translateX(0); } }

.figure {
  max-width: 650px;
  position: relative; }
  .figure__image {
    position: relative; }
  .figure__label {
    position: absolute;
    bottom: -7px;
    left: 0;
    background: white;
    color: #000000;
    font-weight: 500;
    padding: 21.33333px 21.33333px 0 0;
    transition: bottom .3s ease; }
  .figure__copy {
    font-size: 12px;
    font-weight: 500;
    color: #BCBBBB;
    text-transform: uppercase;
    text-align: right;
    padding-top: 8px; }
  .figure--rounded .figure__image {
    aspect-ratio: 1/1;
    border-radius: 100%; }

.link {
  position: relative;
  padding-bottom: 4px;
  color: inherit; }
  .link:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 32px;
    display: block;
    height: 1px;
    left: calc(50% - 16px);
    background-color: transparent;
    transition: width 0.3s ease, left 0.3s ease, background-color 0.3s ease; }
  @media (hover) {
    .link:hover {
      color: var(--tegel-black); }
      .link:hover:after {
        background-color: var(--tegel-black);
        width: 100%;
        left: 0; } }

.socials {
  position: relative;
  grid-area: socials;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  width: 80px;
  justify-content: center;
  align-items: center;
  transition: opacity .3s, top .3s; }
  .menu-open .socials {
    opacity: 0;
    pointer-events: none; }
  @media screen and (min-width: 48em) {
    .socials {
      position: fixed;
      right: 0;
      top: 50vh;
      transform: translateY(-50%);
      flex-direction: column;
      margin: unset;
      padding-top: 0;
      padding-bottom: 0; } }
  .socials__item {
    max-height: 15px;
    width: 19.2px;
    height: 19.2px; }
    .socials__item svg {
      width: 100%;
      height: 100%; }
  @media screen and (min-width: 48em) {
    .socials__item + .socials__item {
      margin-top: 16px; } }

.intro {
  display: grid;
  grid-template-areas: "image" "content"; }
  @media screen and (min-width: 64em) {
    .intro {
      grid-template-columns: 80px 2fr 3fr 80px;
      grid-template-areas: ". content image .";
      height: calc(100vh - 80px); } }
  .intro__content {
    grid-area: content;
    display: flex;
    align-items: center;
    padding: 32px; }
    @media screen and (min-width: 64em) {
      .intro__content {
        padding-right: 64px; } }
  .intro__image {
    grid-area: image;
    display: flex; }
    .intro__image > * {
      flex: 1; }
    @media screen and (min-width: 64em) {
      .intro__image img {
        height: calc(100vh - 80px); } }

.content-block {
  display: grid; }
  .content-block__left, .content-block__right {
    display: flex;
    align-items: center; }
  .content-block__left {
    grid-area: left; }
  .content-block__right {
    grid-area: right; }
  @media screen and (min-width: 48em) {
    .content-block__left .text-block {
      padding-right: 64px; }
    .content-block__right .text-block {
      padding-left: 64px; } }
  .content-block__item {
    grid-area: 1/1;
    display: grid;
    grid-template-areas: "left"  "right";
    gap: 32px; }
    @media screen and (min-width: 48em) {
      .content-block__item {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        gap: 0; } }
    .content-block__item--reverse {
      grid-template-areas: "left"  "right"; }
      @media screen and (min-width: 48em) {
        .content-block__item--reverse {
          grid-template-areas: "left right"; } }
      .content-block__item--reverse .content-block__right {
        justify-content: center; }
  @media screen and (min-width: 48em) {
    .content-block__before {
      padding-top: 128px;
      padding-bottom: 128px; } }
  .content-block__after {
    grid-template-areas: ". block"; }
    @media screen and (max-width: 47.9375em) {
      .content-block__after {
        display: none; } }
  .content-block__block {
    grid-area: block;
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-areas: "pager content";
    gap: 32px; }
    .content-block__block__pager {
      grid-area: pager;
      display: flex;
      align-items: flex-end;
      color: #BCBBBB; }
    .content-block__block__content {
      grid-area: content;
      display: flex;
      background: #F9F9F9; }
      .content-block__block__content > * {
        flex: 1; }
  .content-block figure {
    height: 100%; }

.text-block {
  max-width: 480px; }
  .text-block--center {
    text-align: center; }
  .text-block__header {
    margin-bottom: 32px; }
  .text-block__content {
    margin-bottom: 32px;
    color: #BCBBBB; }
  .text-block *:last-child {
    margin-bottom: 0; }

.steps {
  counter-reset: step-counter;
  max-width: 650px;
  margin: 0 auto; }
  .steps__header {
    margin-bottom: 80px; }

.step {
  display: grid;
  gap: 32px;
  margin-bottom: 32px;
  counter-increment: step-counter; }
  .step + .step {
    margin-top: 48px; }
  @media screen and (min-width: 48em) {
    .step {
      gap: 96px;
      grid-template-columns: 1fr 3fr; } }
  .step__image {
    position: relative;
    max-width: 160px;
    max-height: 160px; }
    @media screen and (max-width: 47.9375em) {
      .step__image {
        margin: 0 auto; } }
    .step__image:after {
      position: absolute;
      right: -32px;
      bottom: -19.2px;
      content: counter(step-counter) ". ";
      font-family: "DM Serif Display", Georgia, "Times New Roman", Times, serif;
      font-size: 100px;
      line-height: .7;
      color: #EAE7E7; }
  .step__right {
    color: #BCBBBB;
    display: flex;
    align-items: center;
    text-align: center; }
    @media screen and (min-width: 48em) {
      .step__right {
        text-align: left; } }

.cta {
  background: #F9F9F9;
  padding: 32px; }
  @media screen and (min-width: 48em) {
    .cta {
      padding: 64px; } }
  .cta > * {
    max-width: 380px;
    margin: 0 auto; }
  .cta .form__row:last-child {
    justify-content: flex-end; }
  .cta .form__action {
    margin-top: 16px; }
  .cta .form {
    text-align: left; }

.footer__top {
  font-size: 14px;
  line-height: 26px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  padding-top: 0;
  padding-bottom: 64px; }
  @media screen and (min-width: 48em) {
    .footer__top {
      padding-top: 64px; } }
  @media screen and (min-width: 80em) {
    .footer__top {
      grid-template-columns: 175px auto auto auto;
      gap: 96px; } }

.footer__bottom {
  color: #BCBBBB;
  font-size: 12px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid #EAE7E7;
  display: flex;
  flex-direction: column;
  gap: 10.66667px; }
  @media screen and (min-width: 48em) {
    .footer__bottom {
      flex-direction: row;
      gap: 0;
      justify-content: space-between; } }

.footer__logo a {
  display: block;
  max-width: 80px; }
  .footer__logo a svg {
    max-width: 80px;
    height: auto; }

.footer__slogan {
  font-family: "DM Serif Display", Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  margin-top: 15px; }

.footer__info__block {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  line-height: 31.5px; }
  .footer__info__block .link {
    margin: 0;
    padding-bottom: 0; }

.footer__nav {
  display: flex;
  line-height: 31.5px;
  column-gap: 32px; }
  @media screen and (max-width: 37.4375em) {
    .footer__nav {
      flex-wrap: wrap; } }
  .footer__nav__column {
    display: flex;
    flex-direction: column; }

.footer__subscribe__title {
  font-family: "Gilroy", Helvetica, Arial, sans-serif;
  margin-bottom: 32px;
  max-width: 220px; }

.footer__subscribe .form__row:last-child {
  justify-content: flex-end; }

.footer__subscribe .form__action {
  margin-top: 16px; }

.footer__disclaimer .link + .link {
  margin-left: 32px; }

.overlay {
  opacity: 0;
  transform: translate(-100vw, -100vh);
  transition: opacity .3s ease;
  transition-delay: .3s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding: 16px 32px;
  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: auto 1fr auto;
  height: 100%;
  pointer-events: none; }
  .menu-open .overlay {
    opacity: 1;
    transform: translate(0, 0); }
  @media screen and (min-width: 48em) {
    .overlay {
      padding: 32px; } }
  .overlay__header {
    grid-area: header; }
    .overlay__header__logo {
      display: inline-block;
      pointer-events: all;
      max-width: 100px;
      height: auto; }
      .overlay__header__logo svg {
        height: 64px; }
        @media screen and (min-width: 48em) {
          .overlay__header__logo svg {
            height: 96px; } }
  .overlay__content {
    grid-area: content;
    pointer-events: all;
    padding-right: 64px; }
    @media screen and (max-width: 63.9375em) {
      .overlay__content {
        overflow: scroll; }
        .overlay__content .overlay__wrap {
          padding-top: 16px;
          padding-bottom: 0; } }
  .overlay__footer {
    grid-area: footer;
    pointer-events: all; }
    .overlay__footer .wrap {
      padding-top: 16px;
      padding-bottom: 0;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;
      justify-content: space-between; }
      .overlay__footer .wrap .overlay__left .footer-nav {
        display: flex;
        flex-wrap: nowrap; }
        .overlay__footer .wrap .overlay__left .footer-nav .footer-nav__item + .footer-nav__item {
          padding-left: 16px; }
  .overlay__wrap {
    display: flex;
    flex-direction: column;
    padding: 32px 0; }
    @media screen and (min-width: 64em) {
      .overlay__wrap {
        padding: 0 unset;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%; } }
  .overlay__left {
    max-width: 800px; }

.main-nav {
  display: flex;
  flex-wrap: wrap; }
  .main-nav__item {
    font-family: "DM Serif Display", Georgia, "Times New Roman", Times, serif;
    font-size: 30px;
    line-height: 34px;
    flex: 1 1 100%;
    padding: 8px 0; }
    @media screen and (min-width: 64em) {
      .main-nav__item {
        font-size: 48px;
        line-height: 55px;
        flex: 1 1 50%;
        padding: 32px 0; } }
  .main-nav__link {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: color .3s ease;
    color: #BCBBBB; }
    @media (hover) {
      .main-nav__link:hover {
        color: white; }
        .main-nav__link:hover .icon svg {
          transform: translateX(0); }
        .main-nav__link:hover .text {
          transform: translateX(40px); }
      .main-nav__link.active {
        color: white; } }
    .main-nav__link .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
      .main-nav__link .icon svg {
        transition: transform .3s ease;
        transform: translateX(-40px); }
        .main-nav__link .icon svg #long-arrow-right {
          fill: white; }
    .main-nav__link .text {
      transform: translateX(0);
      transition: transform .3s ease; }
      @media screen and (max-width: 37.4375em) {
        .main-nav__link .text {
          font-size: 26px;
          line-height: 28px; } }

.side-nav {
  display: flex;
  flex-direction: column;
  padding-top: 16px; }
  @media screen and (min-width: 64em) {
    .side-nav {
      flex-direction: row;
      padding-top: 0; } }
  .side-nav__item + .side-nav__item {
    padding-left: 0; }
    @media screen and (min-width: 64em) {
      .side-nav__item + .side-nav__item {
        padding-left: 32px; } }
  .side-nav__link {
    transition: color .3s ease;
    color: #BCBBBB; }
    .side-nav__link:hover {
      color: white; }
    .side-nav__link.active {
      color: white; }
      .side-nav__link.active:after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--tegel-black); }

.footer-nav {
  display: flex;
  flex-wrap: wrap; }
  .footer-nav__item + .footer-nav__item {
    padding-left: 32px; }
  .footer-nav__link {
    color: #6A6A6A;
    text-transform: capitalize; }
    .footer-nav__link.active {
      text-decoration: underline; }
    .footer-nav__link:hover {
      color: white; }

.categories-grid {
  display: grid;
  gap: 48px;
  grid-template-areas: "c-1" "c-2" "c-3" "c-4" "c-5"; }
  @media screen and (min-width: 48em) {
    .categories-grid {
      grid-template-areas: "c-1 c-2" "c-3 c-4" "c-3 c-5";
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr; } }
  @media screen and (min-width: 64em) {
    .categories-grid {
      grid-template-areas: "c-1 c-3 c-4" "c-2 c-3 c-5";
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr; } }
  .categories-grid__item {
    display: flex; }
    .categories-grid__item > * {
      flex: 1; }
    .categories-grid__item img {
      aspect-ratio: 4 / 3; }
    .categories-grid__item--c-1 {
      grid-area: c-1; }
    .categories-grid__item--c-2 {
      grid-area: c-2; }
    .categories-grid__item--c-3 {
      grid-area: c-3; }
      .categories-grid__item--c-3 img {
        aspect-ratio: 3 / 4; }
    .categories-grid__item--c-4 {
      grid-area: c-4; }
    .categories-grid__item--c-5 {
      grid-area: c-5; }
    .categories-grid__item--cta {
      align-items: stretch; }
    @media (hover) {
      .categories-grid__item:hover .figure__label {
        bottom: 0; } }
  .categories-grid__cta {
    background: #F9F9F9;
    position: relative;
    padding: 32px 32px 80px 32px; }
    .categories-grid__cta .button__icon-block {
      position: absolute;
      right: 0;
      bottom: 0; }

.cta-inspiration {
  display: grid; }
  @media screen and (min-width: 48em) {
    .cta-inspiration {
      grid-template-columns: 1fr 96px;
      gap: 16px; } }
  .cta-inspiration__images {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .cta-inspiration__images .cta-inspiration__images--thumb {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.3s;
      opacity: 0; }
      .cta-inspiration__images .cta-inspiration__images--thumb.is-active {
        opacity: 1; }
  .cta-inspiration__main {
    position: relative; }
    .cta-inspiration__main .button__icon-block {
      position: absolute;
      right: 0;
      bottom: 0; }
  .cta-inspiration__thumbs {
    display: none; }
    @media screen and (min-width: 48em) {
      .cta-inspiration__thumbs {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 80px; } }
  .cta-inspiration__thumb {
    cursor: pointer; }
    .cta-inspiration__thumb figure,
    .cta-inspiration__thumb img {
      aspect-ratio: 1/1; }
  .cta-inspiration__thumb + .cta-inspiration__thumb {
    margin-top: 16px; }

.brands__header {
  padding-bottom: 64px;
  display: flex;
  justify-content: center;
  align-items: center; }

.brands__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 64px;
  column-gap: 32px;
  margin: 0 auto;
  max-width: 800px; }

.brands__item {
  max-width: 128px;
  display: flex;
  justify-content: center;
  align-items: center; }

.blocks__header {
  padding-bottom: 64px;
  display: flex;
  justify-content: center;
  align-items: center; }

.blocks__content {
  display: flex;
  flex-wrap: wrap;
  gap: 32px; }

.blocks__item {
  flex: 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  @media screen and (min-width: 48em) {
    .blocks__item {
      flex: 0 calc(50% - (32px)); } }
  @media screen and (min-width: 64em) {
    .blocks__item {
      flex: 0 calc(25% - (24px)); } }

.block__image {
  width: 100%; }
  .block__image figure {
    width: 100%;
    aspect-ratio: 1/1; }

.block__content {
  color: #BCBBBB;
  padding-top: 32px; }

.inspiration__header {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  text-align: center; }
  .inspiration__header > * {
    max-width: 640px; }

.inspiration__filter {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin-bottom: 64px; }
  .inspiration__filter__item {
    border: 1px solid #000000;
    padding: 16px 21.33333px;
    line-height: 1;
    cursor: pointer;
    transition: border-color .3s ease, color .3s ease; }
    .inspiration__filter__item.active {
      background: #000000;
      color: white; }
    @media (hover) {
      .inspiration__filter__item:not(.active):hover {
        color: var(--tegel-black);
        border-color: var(--tegel-black); } }

.inspiration__item {
  position: relative; }
  @media (hover) {
    .inspiration__item:hover:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3); }
    .inspiration__item:hover:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("../images/svgs/icon-magnifier.svg");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat; } }

.inspiration__grid {
  display: grid;
  grid-template-columns: 1;
  gap: 16px; }
  @media screen and (min-width: 48em) {
    .inspiration__grid {
      grid-template-columns: repeat(3, minmax(0, 1fr)); } }

.inspiration__grid__item {
  display: flex;
  flex-direction: column;
  grid-column: span 1 / span 1;
  gap: 16px; }

.inspiration__item {
  width: 100%; }

.page__header {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  text-align: center; }
  .page__header > * {
    max-width: 640px; }
  .page__header--align-left {
    text-align: left; }

.page__filter {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 64px; }
  .page__filter__item {
    border: 1px solid #000000;
    padding: 16px 21.33333px;
    line-height: 1;
    cursor: pointer;
    transition: border-color .3s ease, color .3s ease; }
    .page__filter__item.active {
      background: #000000;
      color: white; }
    @media (hover) {
      .page__filter__item:not(.active):hover {
        color: var(--tegel-black);
        border-color: var(--tegel-black); } }

.page__content {
  padding-top: 32px; }

.blog__item {
  margin-bottom: 16px;
  position: relative; }
  @media (hover) {
    .blog__item:hover .blog__text {
      border-color: rgba(241, 144, 0, 0.3); }
    .blog__item:hover .button {
      color: var(--tegel-black); }
    .blog__item:hover .icon svg {
      fill: var(--tegel-black); } }

.blog__image {
  position: relative; }

.blog__tag {
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  color: white;
  padding: 10.66667px; }

.blog__text {
  padding: 32px;
  border: 2px solid #F9F9F9;
  border-top: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: border-color .3s ease; }
  .blog__text .button {
    justify-content: flex-start;
    transition: color .3s ease; }
  .blog__text .text {
    color: #BCBBBB; }
    .blog__text .text p {
      margin: 0;
      padding: 0; }

.blog__item, .masonry-item {
  width: 100%; }
  @media screen and (min-width: 48em) {
    .blog__item, .masonry-item {
      width: calc(33% - 10.66667px); } }

.detail {
  margin-top: 32px;
  margin-bottom: 32px; }
  .detail__breadcrumb {
    display: flex;
    margin-bottom: 16px; }
    .detail__breadcrumb__item {
      color: #BCBBBB; }
      .detail__breadcrumb__item a {
        color: #BCBBBB; }
    .detail__breadcrumb__item + .detail__breadcrumb__item:before {
      content: ' > ';
      padding-left: 16px;
      padding-right: 16px; }
  .detail__header {
    display: flex;
    max-height: 320px;
    position: relative;
    z-index: -1; }
    .detail__header figure {
      flex: 1;
      max-width: 100%; }
      .detail__header figure img {
        height: 100%; }
  .detail__frame {
    background: white;
    color: #BCBBBB; }
    @media screen and (min-width: 64em) {
      .detail__frame {
        margin-top: -64px;
        margin-left: 96px;
        margin-right: 96px;
        padding: 64px 96px; } }
    @media screen and (min-width: 120em) {
      .detail__frame {
        margin-left: 160px;
        margin-right: 160px;
        padding: 64px 160px; } }
    .detail__frame__intro {
      padding-top: 64px;
      padding-bottom: 64px; }
    .detail__frame__content {
      border-top: 1px solid #BCBBBB;
      padding-top: 64px;
      padding-bottom: 64px; }
    .detail__frame__block + .detail__frame__block {
      padding-top: 64px; }
    .detail__frame__image {
      margin-left: -160px;
      margin-right: -160px; }
      .detail__frame__image figure {
        width: 100%; }
    .detail__frame__quote {
      border-left: 1px solid var(--tegel-black);
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 32px;
      font-size: 24px;
      line-height: 34px;
      color: #000000;
      font-family: "DM Serif Display", Georgia, "Times New Roman", Times, serif;
      max-width: 350px; }
    .detail__frame__brands {
      display: flex;
      flex-wrap: wrap;
      gap: 32px; }
      .detail__frame__brands > .figure {
        flex: 0 calc(33% - (21.33333px));
        display: flex;
        justify-content: center;
        align-items: center; }
        .detail__frame__brands > .figure img {
          max-height: 100px;
          max-width: 200px; }
    .detail__frame__title {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 16px;
      color: var(--tegel-black); }
  .detail__title {
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
    color: #000000;
    margin-bottom: 64px; }
  .detail__link {
    color: #BCBBBB; }

.jobs {
  max-width: 1120px; }
  @media screen and (min-width: 48em) {
    .jobs {
      padding-top: 96px;
      margin: 0 auto; } }
  .jobs__item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F9F9F9;
    padding-top: 24px;
    padding-bottom: 24px; }
    .jobs__item:last-child {
      border-bottom: 1px solid #F9F9F9; }
    @media (hover) {
      .jobs__item:hover {
        color: var(--tegel-black); }
        .jobs__item:hover svg {
          fill: var(--tegel-black); } }

.contact {
  display: grid;
  gap: 32px; }
  @media screen and (min-width: 64em) {
    .contact {
      grid-template-columns: 1fr 1fr; } }
  .contact__content {
    margin-top: 32px; }
  .contact__form .form {
    margin-top: 0; }
  .contact__map__wrapper {
    height: 320px; }
    @media screen and (min-width: 48em) {
      .contact__map__wrapper {
        height: 640px; } }

.slideshow__image img {
  min-height: 350px !important;
  height: 55vh !important;
  max-height: 350px !important; }
  @media screen and (min-width: 48em) {
    .slideshow__image img {
      min-height: 350px !important;
      height: 75vh !important;
      max-height: 550px !important; } }

@media screen and (max-width: 37.5625em) {
  .slideshow .vertical-dots {
    position: relative;
    right: 0;
    top: 0;
    transform: translate(0px, 0px);
    display: flex;
    justify-content: center; } }

.ContactPage .map-container {
  display: grid;
  grid-template-columns: 1fr 40vw;
  grid-template-rows: 800px; }
  .ContactPage .map-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media screen and (min-width: 64em) {
    .ContactPage .map-container .contact__map {
      padding: 0;
      width: 100%;
      height: 100%; }
      .ContactPage .map-container .contact__map__wrapper {
        height: 100%; } }
  @media screen and (max-width: 87.5em) {
    .ContactPage .map-container {
      grid-template-columns: 1fr 50vw;
      grid-template-rows: 650px; } }
  @media screen and (max-width: 64em) {
    .ContactPage .map-container {
      display: flex;
      flex-direction: column; }
      .ContactPage .map-container img {
        height: 400px; }
      .ContactPage .map-container .contact__map {
        width: 100%;
        height: 100%; } }
