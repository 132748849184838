.page {

    $self: &;

    &__header {
        margin-bottom: u($rule);

        display: flex;
        justify-content: center;
        
        text-align: center;

        > * {
            max-width: u($rule*20);
        }

        &--align-left {
            text-align: left;
        }
    }
    
    &__filter {
        display: flex; 
        gap: u($rule/2);

        justify-content: center;

        margin-bottom: u($rule*2);

        &__item {
            border: 1px solid $cc-black; 

            padding: u($rule/2) u($rule/1.5);
            line-height: 1;

            cursor: pointer;

            transition: border-color .3s ease, color .3s ease;

            &.active {
                background: $cc-black;
                color: $cc-white;
            }

            @media (hover) {

                &:not(.active):hover {
                    color: $cc-orange;
                    border-color: $cc-orange;
                }

            }
        }
    }

    &__content {

        padding-top: u($rule);

    }

    &__footer {
        
    }


}