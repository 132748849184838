.section {

    $self: &;

    padding-top: u($rule*3);
    padding-bottom: u($rule*3);

    // &:first-child {
    //     padding-top: u($rule*5);
    // }

    @include mq($from: $viewport--lg) {
        padding-top: u($rule*5);
        padding-bottom: u($rule*5);
    }

    & + & {
        padding-top: 0;
    }

}