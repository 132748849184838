.header {

    display: flex; 

    justify-content: space-between;
  
    &__logo {

        display: flex;
        justify-content: center;
        align-items: center;

        padding-left: u($rule/2);

        max-width: 100px;
    }
    
    &__nav {
        flex: 1;
        
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding-right: u($rule);
        
        .active {
            color: $cc-orange;
        }

        @include mq($until: $viewport--lg--until) {
            display: none; 
        }

    }

    &__hamburger {
        width: u($sidebar);
        height: u($sidebar);
    
        background-color: $cc-black; 
        color: $cc-white;
    
        display: flex;
        justify-content: center;
        align-items: center;

        transition: box-shadow .5s ease-in-out;

        z-index: 999;

        .menu-open & {
            box-shadow: 0 0 0 100vw $cc-black, 0 0 0 100vh $cc-black;
            border-radius: 0;
        }

    }

}