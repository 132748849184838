/* Main */
html {
    background-color: $bg-color;
    color: $text-color;

    font-family: $font-default;
    height: 100%;
    scroll-behavior: smooth;

    font-size: u($font-size);
    line-height: u($line-height);

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
}

.hide {
    display: none;
}

.inactive {
    opacity: .4;
    pointer-events: none;
}

.menu-open {
    overflow: hidden;
    height: 100%;
} 
