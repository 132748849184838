.content-block {

    $self: &; 

    display: grid;


    &__left, &__right {

        display: flex; 
        align-items: center;

    }

    &__left { 
        grid-area: left;
    } 

    &__right { 
        grid-area: right;
    } 

    @include mq($from: $viewport--md) {
        &__left {
            .text-block {
                padding-right: u($rule*2);
            }
        }

        &__right {
            .text-block {
                padding-left: u($rule*2);
            }
        }
    }

    &__item {
        grid-area: 1/1;

        display: grid;
        grid-template-areas: 
            "left" 
            "right";

        gap: 32px;

        @include mq($from: $viewport--md) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
                "left right";

            gap: 0;
        }
        

        &--reverse {

            grid-template-areas: 
                "left" 
                "right";

            @include mq($from: $viewport--md) {
                grid-template-areas: 
                    "left right";
            }
            
            #{$self}__right {
                justify-content: center;
            }

        } 

    }

    @include mq($from: $viewport--md) {
        &__before {

            padding-top: u($rule*4);
            padding-bottom: u($rule*4);

        }
    }


    &__after {

        grid-template-areas: ". block";

        @include mq($until: $viewport--md--until) {
            display: none;
        }

    }
    
    &__block {
        grid-area: block;
        
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-areas: "pager content";
        gap: u($rule);
        
        &__pager {
            grid-area: pager;
            display: flex;
            align-items: flex-end;
            color: $cc-grey;
        }
        
        &__content { 
            grid-area: content;
            display: flex;
            background: $cc-grey-bg; 
            
            > * {
                flex: 1;
            }
        }
    }

    figure {
        height: 100%;
    }

}