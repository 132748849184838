.intro {
    display: grid; 

    grid-template-areas: 
        "image"
        "content";

    @include mq($from: $viewport--lg) {
        grid-template-columns: u($rule*2.5) 2fr 3fr u($rule*2.5);
        grid-template-areas: ". content image .";

        height: calc(100vh - #{u($structure-header-height)});
    }

    &__content {
        grid-area: content;

        display: flex;
        align-items: center;

        padding: u($rule);
        
        @include mq($from: $viewport--lg) {
            padding-right: u($rule*2);
        }
        
    }
    
    &__image {
        grid-area: image;

        display: flex;

        > * {
            flex: 1;
        }

        @include mq($from: $viewport--lg) {
            img {
                height: calc(100vh - #{u($structure-header-height)});
            }
        }
    }

}