/* List */
.list {
    margin-bottom: u($spacing-unit);
    
    li {
        padding-left: u($list-gutter--l);
        margin-bottom: u(5);
        list-style-type: none;
        position: relative;

        strong {
            font-weight: bold;
        }
    }

    ol,
    ul {
        margin-top: u(4);
        margin-bottom: u(4);
    }
}

// Unordered List
ul.list {
    list-style-type: none;

    li:before {
        content: "\2022"; 
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        color: var(--font-color); 
        font-size: 25px;
        font-weight: bold;
        display: inline-block; 
        width: 25px; 
    }
}